/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')

require("trix")
require("@rails/actiontext")
require("tablesorter")
//require("flatpickr")
import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")
document.addEventListener("turbolinks:load", () => {
	var flatpickr_end = flatpickr("#flatpickr-event-end", {
		enableTime: true, altInput: true, altFormat: "F j, Y h:i K"})
	$("#flatpickr-event-start").flatpickr({
		enableTime: true, altInput: true, altFormat: "F j, Y h:i K", onChange: function(dateStr, dateObj) {
			flatpickr_end.set("minDate", dateObj);
			flatpickr_end.setDate(dateObj);
		}
	})
	/*
	flatpickr("[data-behavior='flatpickr']", {});
	flatpickr(".flatpickr.datetime", {enableTime: true, altInput:true, altFormat: "F j, Y h:i K"});
	$("#flatpickr-event-start").flatpickr(onChange: function(dateStr, dateObj){
		$("#flatpickr-event-end").flatpickr().set("minDate", dateObj);
		$("#flatpickr-event-end").flatpickr().setDate(dateObj);		
	});
	*/
});